.section-offer-carousel {
    display: block;
    background-color: $color-primary;
    padding-top: 18px;
    padding-bottom: 18px;

    .item {
        display: inline-block;
        position: relative;
        width: calc(100%);
        text-align: center;
        cursor: pointer;

        h2 {
            color: $color-white;
        }
    }
}


@include media-breakpoint-up (xxl) {
    
    .section-offer-carousel {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    
}