.list {
    &.list-radio {
        li[data-value] {
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            &.disabled {
                cursor: default;
            }
            &:before {
                content: "radio_button_unchecked";
                left: 0%;
                top: 20px;
                //top: 50%;
                //transform: translateY(-50%);
                width: 19px;
                height: 19px;
                color: $color-rock;
                position: absolute;
                font-family: "Material Icons";
                font-size: 19px;
                line-height: 19px;
            }
        }
        li.checked:before {
            content: "radio_button_checked";
        }
    }
    &.list-radio.disabled {
        li[data-value] {
            cursor: default;
        }
    }
    &.list-checkbox {
        li[data-value] {
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            &.disabled {
                cursor: default;
            }
            &:before {
                content: "check_box_outline_blank";
                left: 0%;
                top: 50%;
                transform: translateY(-50%);
                width: 19px;
                height: 19px;
                color: #2abfc9;
                position: absolute;
                font-family: "Material Icons";
                font-size: 19px;
                line-height: 19px;
            }
        }
        li.checked:before {
            content: "check_box";
        }
    }
    &.list-checkbox.disabled {
        li[data-value] {
            cursor: default;
        }
    }
}
