.top-content {

    padding-top: 25px;
    margin-bottom: 20px;

    &.color-light-sand{
        background-color: $color-light-sand;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    h1 {
        color: $color-black;
        margin-bottom: 0px;
    }

    h2 {
        color: $color-black;
        margin-bottom: 0px;
    }

    p {
        color: $color-black;
        margin-top: 20px;
    }
}

@include media-breakpoint-up(lg) {

    .top-content {
        padding-top: 25px;
        

        &.color-light-sand{
            padding-top: 35px;
            padding-bottom: 35px;
        }

    }
   
}

