select {
    @include body-text();
    padding: 20px 10px 20px 10px;
    border-radius: 6px;
    border: 1px solid $color-stone;
    background-color: $input-bg-color;
    width: 100%;
    margin-bottom: 20px;
    color: $color-black;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    &:active,
    &:focus,
    &:hover {
        outline: none;
    }
    &.invalid {
      border: 1px solid $color-attention;
    }
}

@include media-breakpoint-up(xxl) {

  select {
    @include body-text(xxl);
  }
}