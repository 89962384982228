.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
  background-color: $color-light-sand;
 

  ul {
    list-style: none;
    padding:0px;

    margin-top: 18px;
    margin-bottom: 18px;

    li, span {
      font-family: $helvetica-regular;
      font-size: 15px;
      letter-spacing: 0.75px;
    }
  }
}


.breadcrumb-item {
  margin-right: 0.5rem;
}

.breadcrumb-item a {
  text-decoration: none;
  color: $color-primary;
  font-size: 15px;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

.breadcrumb-item.active {
  color: $color-black;
}
