header {
  height: 54px;


  .wrapper-nav {
    height: 54px;
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 2000;
    display: flex;
    align-items: center;
    background-color: $color-white;

    &.wrapper-nav-color {
      background-color: $color-sand;
      backdrop-filter: 0px;
    }

    .logo {
      height: 25px;
    }

    .menu-open {
      right: 0 !important;
    }

    .mobile-nav {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      background-color: #f2f2f2;
      position: fixed;
      right: -100%;
      top: 54px;
      height: calc(100vh - 54px);
      width: 100%;
      transition: all 0.5s;
      z-index: 1000;
      max-width: 100%;
      overflow-y: auto;

      .logo {
        margin-top: 15px;
        margin-left: 15px;
      }

      .form-control::after {
        content: "search";
        width: 50px;
        height: 50px;
        color: $color-primary;
        font-family: "Material Icons";
      }

      ul {
        list-style: none;
        padding-left: 0px;
        overflow: auto;

        li {
          padding: 0;
          margin: 0;
          line-height: 50px;
          position: relative;

          &:first-child {
            position: relative;
          }

          &:last-child {
            border-bottom: none 0;
          }

          a {
            font-family: $hansen-bold;
            color: $color-black;
            text-transform: uppercase;
            padding: 0;
            font-size: 10px;
            letter-spacing: 0.7px;
            line-height: 50px;
            display: block;
            transition: all 0.2s;
            padding-left: 0px;
            width: calc(100% - 30px);
            margin: 0 auto;

            &:hover {
              color: $color-black;
              text-decoration: none;
            }

            &:after {
              content: "";
              background-color: $color-black;
              display: block;
              height: 1px;
              width: calc(100% - 30px);
              position: absolute;
              bottom: 0;
            }
          }

          &.menu-item-has-children {
            > ul {
              display: none;
              padding: 0 0 0 0px;

              > li {
                background-color: $color-white;
                padding-left: 30px;

                > a {
                  text-transform: none;
                  font-size: 16px;
                  letter-spacing: 0px;
                  font-family: $headline-font;

                  &:after {
                    content: "";
                    background-color: $color-stone;
                    display: block;
                    height: 1px;
                    width: calc(100% - 60px);
                    position: absolute;
                    bottom: 0;
                  }
                }

                &:last-child() {
                  a {
                    &:after {
                      content: "";
                      background-color: transparent;
                    }
                  }
                }
              }
            }

            > span {
              display: block;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 50px;
              width: 50px;
              height: 50px;
              text-align: center;
              cursor: pointer;

              &:before,
              &:after {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                font-size: 19px;
                font-family: "Material Icons";
                color: $color-primary;
              }

              &:before {
                content: "add_circle";
              }
              &:after {
                content: "remove_circle";
                display: none;
              }
            }
          }

          &.submenu-is-open {
            > ul {
              display: block;
            }

            > span:after {
              display: block;
            }
          }
        }
      }
    }
  }

  .login-icon {
    margin-right: 20px;

    &:after {
      content: "account_circle";
      font-family: "Material Icons";
      font-size: 24px;
      color: $color-black;
    }
  }

  .holder-search-menu {
    margin-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .nav-icon {
    position: relative;
    cursor: pointer;
    z-index: 3000;
    width: 20px;

    &:before {
      content: "";
      background-image: url(./../../assets/icons/menu-icon.svg);
      background-repeat: no-repeat;
      height: 20px;
      width: 20px;
      background-size: contain;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
    }

    &.open {
      &:before {
        display: none;
      }

      &:after {
        content: "highlight_off";
        font-family: "Material Icons";
        font-size: 24px;
        color: $color-primary;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        right: -2px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  body {
    &.position-background {
      position: relative;
    }
  }
  header {
    height: 65px;


    .nav-icon {
      width: 20px;

      &:before {
        height: 20px;
        width: 20px;
      }

      &.open {
        &:after {
          font-size: 26px;
          right: -3px;
        }
      }
    }

    .wrapper-nav {
      height: 65px;
      display: none;
  
      padding: 0 0px 0 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 2000;
      display: flex;
      align-items: center;

      .mobile-nav {
        top: 65px;
        max-width: 600px;
        height: calc(100vh - 90px);
      }

      .logo {
        height: 20px;
      }
    }

    .login-icon {
      margin-right: 20px;

      &:after {
        font-size: 26px;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  header {
    .wrapper-nav {
    
      .mobile-nav {
        height: calc(100vh - 65px);
        ul {
          list-style: none;
          padding-left: 0px;
          overflow: auto;

          li {
            padding: 0;
            margin: 0;
            line-height: 50px;
            position: relative;

            a {
           
              letter-spacing: 1px;
            }

            &.menu-item-has-children {
              > ul {
                > li {
                  > a {
                    font-size: 17px;
                    font-family: $headline-font;
                    text-transform: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
