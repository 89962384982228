.holder-checkbox {
    display: flex;
    align-items: center;
    z-index: 99;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    cursor: pointer;

    label {
        padding-left: 10px;
        margin-bottom: 0px;
    }

    a {
        color: $color-primary;
        text-decoration: none;
        margin-left: 4px;
    }

    
    .checkbox {
        height: 22px;
        width: 22px;
        border: 2px solid $color-black;
        display: block;
        border-radius: 50%;
        position: relative;

        .checked {
            background-color: $color-black;
            width: 12px;
            height: 12px;
            position: absolute;
            top: 50%;
            display: block;
            border-radius: 50%;
            left: 0px;
            right: 0px;
            text-align: center;
            margin: 0 auto;
            transform: translateY(-50%);
        }
    }

    &.inactive {
        cursor: default;
        .checkbox {
            opacity: 0.2;
        }
    }
}