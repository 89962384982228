// Ripple animation
.ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
      position: absolute;
      border: 2px solid $color-primary;
      opacity: 1;
      border-radius: 50%;
      animation: ripple-animation 1600ms cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    div:nth-child(2) {
      animation-delay: -800ms;
    }
  }
  @keyframes ripple-animation {
    0% {
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  

  .loading-container {
    position: relative;
    margin-top: 50px;
    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .loading {
    display: flex;
    .ripple {
      width: 40px;
      height: 40px;
    }
    span {
      font-size: 13px;
      line-height: 40px;
      padding-left: 10px;
      font-family: $helvetica-regular;
    }
  }