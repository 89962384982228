
.btn {
    font-family: $hansen-bold;
    text-transform: uppercase;
    border-radius: 6px;


    &:active,
    &:focus,
    &:hover {
        outline: none;
        box-shadow: none;
    }

    &.disabled,
    &.inactive {
        opacity: 0.5
    }

    
    &.primary-btn {
        width: 230px;
        font-size: 18px;
        height: 50px;
        line-height: 40px;
        color: $color-white;
        background-color: $color-primary;

        &.shadow {
            filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.25));
        }
    }

    &.secondary-btn {
 
        font-size: 18px;

        padding: 10px 20px 10px 20px;
        color: $color-white;
        background-color: $color-primary;
        span {
            font-size: inherit;
            font-family: inherit;
        }

        &.active {
            color: $color-white !important;
            background-color: $color-primary !important;
        }

        &.size-fluid {
            width: 100%;
        }

        &.size-small {
            width: 80px;
            font-size: 10px;
            padding: 8px 10px 8px 10px;
            letter-spacing: 1px;
            height: initial;
            line-height: initial;
        }

        &.color-primary {
            color: $color-white;
            background-color: $color-primary;
        }

        
        &.color-secondary {
            background-color: $color-primary;
            color: $color-white;
        }

        &.color-tertiary {
            background-color: transparentize($color-rock, 0.9);
            color: $color-primary;
        }

    }

    &.toggle-primary-btn  {
        min-width: 100px;
        height: 40px;
        font-size: 13px;
        line-height: 30px;
        letter-spacing: 1px;

        span {
            font-size: inherit;
            font-family: inherit;
        }

        &.active {
            color: $color-white !important;
            background-color: $color-primary !important;
        }

        &.color-secondary {
            background-color: $color-white;
            color: $color-primary;
            border: 1px solid $color-stone;
        }

    }
}

.btn-group {
    //margin-left: -1rem;
    display: flex;
    &.justify-content-center {
        margin-left: 0;
    }
    //div {
        //padding-left: 1rem;
        //padding-right: 1rem;
    //}
}




@include media-breakpoint-up(md) {

    .btn {
    
        &.primary-btn {
            width: 220px;
            height: 46px;
            line-height: 36px;
        }
    
        &.secondary-btn {
      

        }

        &.toggle-primary-btn  {
            min-width: 160px;
        }
    }
}


@include media-breakpoint-up(xxl) {

    .btn {
    
        &.primary-btn {
            width: 300px;
            height: 60px;
            line-height: 55px;
        }
    
        &.secondary-btn  {

            height: 50px;
           
        }

        &.toggle-primary-btn  {
            min-width: 190px;
        }
    }
}

.btn-bank-id {
    margin-right: 20px;
    padding-left: 35px;
    padding-right: 35px;
    margin-top: 20px;
}

#bank-id-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

#bank-id-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#qrCode {
    margin-bottom: 40px;
}