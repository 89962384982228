.stats-container {

  .points-table-dot {
    margin-right: 10px;
    flex-grow: 0;

    > div {
      margin-top: 5px;
      border-radius: 50px;
      height: 19px;
      width: 19px;

      &.black {
        background-color: $color-black;
      }

      &.stone {
        background-color: $color-stone;
      }
      &.rock {
        background-color: $color-rock;
      }
    }

  }

}