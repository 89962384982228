input[type="date"],
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"] {
    @include body-text-regular();
    padding: 20px 10px 20px 10px;
    border-radius: 6px;
    border: 1px solid $color-stone;
    background-color: $color-input;
    width: 100%;

    margin-bottom: 20px;
    color: $color-black;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    &::placeholder {
        color: $color-stone;
    }

    &:active,
    &:focus,
    &:hover {
        outline: none;
    }
    &.invalid {
      border: 1px solid $color-attention;
    }
}

input[type="file"] {
  margin-bottom: 20px;
}

textarea {
    @include body-text-regular();
    padding: 6px 10px 7px 10px;
    border-radius: 7px;
    border: 1px solid $color-black;
    background-color: $input-bg-color;
    width: 100%;
    margin-bottom: 0px;
    color: $color-black;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    resize: none;

    &::placeholder {
      color: $color-stone;
    }

    &:active,
    &:focus,
    &:hover {
        outline: none;
    }
    &.invalid {
      border: 1px solid $color-attention;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.with-suggestions) {
    margin-left: -1px !important;
    border-top-left-radius: 7px !important;
    border-bottom-left-radius: 7px !important;
}

.input-group.icon-search {
    position: relative;
    
    &:before {
        font-family: "Material Icons";
        font-size: 19px;
        content: "search";
        position: absolute;
        color: $color-rock;
        z-index: 2;
        right: 8px;
        transform: translateY(-50%);
        top: 50%;
    }
  
    input {
      z-index: 1;
    }
}

.toggle-password-display {
    position: relative;
    > span {
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 19px;
      position: absolute;
      color: $color-rock;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }
}

@include media-breakpoint-up(xxl) {
    input[type="date"],
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    input[type="password"] {
      @include body-text-regular(xxl);
    }

    textarea {
      @include body-text-regular(xxl);
    }

     .toggle-password-display {
        position: relative;
    }
}