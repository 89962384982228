:root, .row {
  --bs-gutter-x: 1rem;
}

@include media-breakpoint-up(md) {
  :root, .row {
      --bs-gutter-x: 3rem;
  }
}

body.nosrcoll {
  overflow: hidden;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	font-size: $rem;
  width: 100%;
}


footer {
  overflow: hidden;
  padding-top: 80px;
}



@include media-breakpoint-up(xxl) {
  footer {
    padding-bottom: 30px;
  }
}
