.form-check-switch {
    display: flex;

    z-index: 99;
  
      
      .form-check-input-switch {
          width: initial;
      }
  
      label {
          padding-left: 48px;
      }
    
      input[type="checkbox"] {
          opacity: 0;

          cursor: pointer;
      }
  
    
      input[type="checkbox"] + label:before {
          content: "toggle_off";
          font-family: 'Material Icons';
          font-size: 48px;
          color: $color-rock;
          position: absolute;
          left: 0;
          z-index: -1;
          top: 50%;
          transform: translateY(-50%);
      
      }
    
      input[type="checkbox"]:checked + label:before {
          content: "toggle_on";
          font-family: 'Material Icons';
          font-size: 48px;
          color: $color-primary;
          position: absolute;
          left: 0;
          z-index: -1;
          top: 50%;
          transform: translateY(-50%);
      
      }
  }
  
  
@include media-breakpoint-up(xxl) {
    .form-check-switch {
      
          label {
              padding-left: 10px;
          }
        
          input[type="checkbox"] {
              width: 48px;
              flex: 0 0 0;
              flex-basis: 48px;
          }
      
        
          input[type="checkbox"] + label:before {
              font-size: 48px;
          }
        
          input[type="checkbox"]:checked + label:before {
              font-size: 48px;
          }
    }
}