.color-rock {
  color: $color-rock;
}

.color-white {
  color: $color-white;
  div,
  span,
  p {
    color: $color-white;
  }
}

.color-black {
  color: $color-black;
}
.color-primary {
  color: $color-primary;
}

.color-rock {
  color: $color-rock;
}
.color-attention {
  color: $color-attention;
}
.color-black {
  color: $color-black;
}

.color-black {
  color: $color-black;
}
.color-stone {
  color: $color-stone;
}

.color-color-rock {
  color: $color-rock;
}

.color-rock {
  color: $color-rock;
}

.color-black {
  color: $color-black;
}


.background-stone {
  background-color: $color-stone;
}
.background-rock {
  background-color: $color-rock;
}
.background-sand {
  background-color: $color-sand;
}

.background-white {
  background-color: $color-white;
}
.background-black {
  background-color: $color-black;
}
.background-primary {
  background-color: $color-primary;
}
.background-secondary {
  background-color: $color-rock;
}
.background-pink {
  background-color: $color-attention;
}
.background-dark {
  background-color: $color-black;
}
.background-charcoal {
  background-color: $color-black;
}
