.modal-overlay {
    position: fixed;
    z-index: 99999;
    top: -5000px;
    right: -5000px;
    bottom: -5000px;
    left: -5000px;
    display: block;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.9);
}

.modal-wrapper {
    position: fixed;
    z-index: 100000;
    top: 10px;
    right: 15px;
    bottom: 0;
    left: 15px;
    display: block;
    overflow: auto;
    text-align: center;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    margin: 0;
}

.modal {
    text-align: left;
    position: relative;
    text-size-adjust: 100%;
    max-width: 100%;
    padding: 0px;
    color: #2b2e38;
    background: #fff;
    display: inline-block;
    top: 0px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    width: 900px;
    border-radius: 8px;

    &.large {
        width: 992px;
    }
    &.medium {
        width: 560px;
    }
    &.narrow {
        width: 450px;
    }

    h3 {
        margin-bottom: 0px;
    }

    .modal-close {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 10px;
        &:before {
            content: "cancel";
            font-family: "Material Icons";
            font-size: 20px;
            color: $color-primary;
        }
    }

    .top-content {
        padding-top: 50px;
        margin-bottom: 0px;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        background-color: $color-white;
    }

    .modal-content {
        padding-top: 0px;
        padding-bottom: 34px;
        padding-left: 20px;
        padding-right: 20px;

        @include media-breakpoint-up(lg) {
            padding: 0 34px 44px 34px;
        }        
    }

    .holder-booking-text {
        margin-top: 20px;
        text-align: center;

        .icon-booking {
            
            &:before {
                font-family: "Material Icons";
                color: $color-rock;
                font-size: 40px;
                line-height: 1;
            }

            &.closed {

                &:before {
                    content: "nightlight_round";
                }
               
            }

            &.locked {
                &:before {
                    content: "lock";
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .modal {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.modal-new {
    width: 850px;

    .modal-close-new {
        width: 24px;
        height: 24px;
        cursor: pointer;
        position: absolute;
        right: 24px;
        top: 24px;
        background-color: #0033FF;
        border-radius: 100%;

        &:before {
            content: "close";
            font-family: "Material Icons";
            font-size: 17px;
            color: #fff;
            line-height: 24px;
            top: 0px;
            position: absolute;
            left: 4px;  
        }
    }
}

.top-content.modal-recruit,
.modal-content.modal-recruit {
    background-color: #fff;
    padding: 30px;

    input {
        font-family: $helvetica-regular;        
    }

    p:first-child {
        padding-bottom: 50px;
    }

    p:last-child {
        font-size: 14px;
        line-height: 20px;
    }

    p {
        font-family: $helvetica-regular;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
    }
}

.top-content.modal-recruit {
    margin-bottom: 0;
    padding-bottom: 0;
    h1 {       
        text-align: start;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
    }
}

.modal-image {
    width: 100%;
    height: 260px;
    background-color: #2b2e38;
}

.modal-code-list {
    font-family: $helvetica-regular;
    display: flex;
    border: 1px solid #ededed;
    justify-content: flex-start;
    min-height: 50px;
    margin-bottom: 30px;
    padding: 0 10px;
    color: #617594;
    font-size: 13px;
    border-radius: 4px;

    flex-direction: column;
    align-items: flex-start;

    > div:nth-child(2) {
        color: #000;
    }
}

.modal-code-copy {
    cursor: pointer;
    position: relative;
}

.modal-form {
    margin-bottom: 30px;
}

.modal-code-copied {
    position: absolute;
    bottom: -20px;
    right: -27px;
    background: #0133ff;
    color: #fff;
    width: 80px;
    text-align: center;
    border-radius: 4px;
}

.modal-input-email {
    margin-bottom: 10px;
}

@include media-breakpoint-up(md) {

    .top-content.modal-recruit,
    .modal-content.modal-recruit {
        p:first-child {
            padding-bottom: 100px;
        }
    }

    .top-content.modal-recruit {
        h1 {
            font-size: 40px;
            line-height: 48px;
        }
    }

    .modal-code-copy {
        margin-left: 10px;
    }

    .modal-input-email {
        margin-bottom: 0;
    }

    .modal-code-list {
        align-items: center;
        flex-direction: row;

        > div:nth-child(2) {
            margin-left: auto;
        }
    }
}