.section-headline {
    margin-top: 0px;
    margin-bottom: 20px;

    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    border: solid 1px rgba(0, 0, 0, 0.25);
    padding: 20px 10px;

    &.inactive {
        opacity: 0.5;
    }
   


   
  

    h6 {
        color: $color-black;
        padding: 0;
        margin: 0;
        user-select: none;
    }

    h2 {
        padding: 0;
        margin: 0;
        line-height: 50px;
    }

    &.mbsh {
        margin-bottom: 10px;
    }

    &.white {
        h4 {
            color: $color-white;
        }
    }

    &.toggle-icon > span:last-child {
        cursor: pointer;
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        bottom: 60px;
        height: 60px;
        &:before {
            font-family: "Material Icons";
            color: $color-primary;
            font-size: 24px;
            line-height: 60px;
            content: "keyboard_arrow_up";
        }
        &.locked {
            cursor: default;
        }
    }
    &.toggle-icon {
        padding-right: 30px;
    }

    &.toggle-icon.closed {
        
        span:last-child:before {
            content: "keyboard_arrow_down";
        }
    }

    > div {
        > span,
        > a {
            font-family: $helvetica-regular;
            font-size: 12px;
            letter-spacing: 0px;
            line-height: 50px;
            cursor: pointer;
            color: $color-rock;
            margin-top: 0px;
            margin-bottom: 0px;
            margin-right: 35px;
            display: block;
        }
    }

    .btn-group {
        margin: 0;
        .btn {
            margin: 0;
            width: auto;
            max-width: none;
            min-width: 0;
            margin-left: 5px;
            margin-right: 5px;
        }
        margin-right: -5px;
    }

    .icon {
        &:after {
            font-family: "Material Icons";
            color: $color-rock;
            font-size: 19px;
            margin-left: 10px;
            display: inline-block;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .icon-filter {
        &:after {
            content: "filter_alt";
        }
    }
    .icon-map {
        &:after {
            content: "place";
        }
    }

    .icon-done {
        &:after {
            content: "done";
        }
    }

    .icon-unfold-more {
        &:after {
            content: "unfold_more";
        }
    }

    .icon-right {
        &:after {
            content: "chevron_right";
            margin: 1px 0 0;
        }
    }

    .icon-search {
        &:after {
            content: "search";
            margin: 1px 0 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .section-headline {



        > div {
            > span,
            > a {
                font-family: $helvetica-regular;
                font-size: 16px;
                letter-spacing: -0.5px;
            }
        }
    }
}

@include media-breakpoint-up(xxl) {
    .section-headline {
     

        &.mbsh {
            margin-bottom: 30px;
        }
    }
}
