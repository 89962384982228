$icon-basket: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bdisplay:inline;opacity:0.5;%7D .st2%7Bfill:%23FFD0FF;%7D .st3%7Bfill:%23FF7BAC;%7D .st4%7Bdisplay:inline;opacity:0.5;fill:%23FF7BAC;%7D .st5%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-linejoin:bevel;stroke-miterlimit:10;%7D .st7%7Bclip-path:url(%23SVGID_2_);%7D .st8%7Bfill:%23FFFFFF;%7D .st9%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:3;%7D .st10%7Bfill:%230000FF;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1'%3E%3Cg%3E%3Cpath d='M23,9v14H3V9H23 M25,7H1v18h24V7L25,7z'/%3E%3C/g%3E%3Cg%3E%3Cpath d='M16.17,3L17,3.83V7H9V3.83L9.83,3H16.17 M17,1H9L7,3v6h12V3L17,1L17,1z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

$icon-find-gym: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bdisplay:inline;opacity:0.5;%7D .st2%7Bfill:%23FFD0FF;%7D .st3%7Bfill:%23FF7BAC;%7D .st4%7Bdisplay:inline;opacity:0.5;fill:%23FF7BAC;%7D .st5%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-linejoin:bevel;stroke-miterlimit:10;%7D .st7%7Bclip-path:url(%23SVGID_2_);%7D .st8%7Bfill:%23FFFFFF;%7D .st9%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:3;%7D .st10%7Bfill:%230000FF;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1'%3E%3Cg%3E%3Cpath d='M12.5,8c1.38,0,2.5,1.12,2.5,2.5S13.88,13,12.5,13S10,11.88,10,10.5S11.12,8,12.5,8 M12.5,6C10.01,6,8,8.01,8,10.5 s2.01,4.5,4.5,4.5s4.5-2.01,4.5-4.5S14.99,6,12.5,6L12.5,6z'/%3E%3C/g%3E%3Cpath class='st9' d='M10.07,24c-0.09-0.16-3.67-6.82-3.76-6.98C6.3,17,5.01,14.6,5,14.58c-0.65-1.22-1.01-2.62-1-4.11 C4.04,5.91,7.53,2,12.5,2v0c4.97,0,8.46,3.91,8.5,8.47c0.01,1.49-0.35,2.88-1,4.11c-0.01,0.02-1.3,2.42-1.32,2.44 c-0.09,0.16-3.67,6.82-3.76,6.98H10.07z'/%3E%3C/g%3E%3C/svg%3E%0A");

$icon-member: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bdisplay:inline;opacity:0.5;%7D .st2%7Bfill:%23FFD0FF;%7D .st3%7Bfill:%23FF7BAC;%7D .st4%7Bdisplay:inline;opacity:0.5;fill:%23FF7BAC;%7D .st5%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-linejoin:bevel;stroke-miterlimit:10;%7D .st7%7Bclip-path:url(%23SVGID_2_);%7D .st8%7Bfill:%23FFFFFF;%7D .st9%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:3;%7D .st10%7Bfill:%230000FF;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1'%3E%3Cg%3E%3Cline class='st5' x1='17' y1='5' x2='25' y2='5'/%3E%3Cline class='st5' x1='21' y1='9' x2='21' y2='1'/%3E%3Cg%3E%3Cg%3E%3Cpath d='M11,7c2.21,0,4,1.79,4,4c0,2.21-1.79,4-4,4s-4-1.79-4-4C7,8.79,8.79,7,11,7 M11,5c-3.31,0-6,2.69-6,6c0,3.31,2.69,6,6,6 s6-2.69,6-6C17,7.69,14.31,5,11,5L11,5z'/%3E%3C/g%3E%3Cg%3E%3Cpath d='M11,17c3.72,0,6.86,2.55,7.75,6H3.25C4.14,19.55,7.28,17,11,17 M11,15C5.48,15,1,19.48,1,25h20C21,19.48,16.52,15,11,15 L11,15z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");

$icon-mypages: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bdisplay:inline;opacity:0.5;%7D .st2%7Bfill:%23FFD0FF;%7D .st3%7Bfill:%23FF7BAC;%7D .st4%7Bdisplay:inline;opacity:0.5;fill:%23FF7BAC;%7D .st5%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-linejoin:bevel;stroke-miterlimit:10;%7D .st7%7Bclip-path:url(%23SVGID_2_);%7D .st8%7Bfill:%23FFFFFF;%7D .st9%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:3;%7D .st10%7Bfill:%230000FF;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1'%3E%3Cg%3E%3Cg%3E%3Cpath d='M13,3c2.76,0,5,2.24,5,5s-2.24,5-5,5s-5-2.24-5-5S10.24,3,13,3 M13,1C9.13,1,6,4.13,6,8c0,3.87,3.13,7,7,7s7-3.13,7-7 C20,4.13,16.87,1,13,1L13,1z'/%3E%3C/g%3E%3Cg%3E%3Cpath d='M13,15c4.83,0,8.87,3.44,9.8,8H3.2C4.13,18.44,8.17,15,13,15 M13,13C6.37,13,1,18.37,1,25h24C25,18.37,19.63,13,13,13 L13,13z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E ");

$icon-service: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bdisplay:inline;opacity:0.5;%7D .st2%7Bfill:%23FFD0FF;%7D .st3%7Bfill:%23FF7BAC;%7D .st4%7Bdisplay:inline;opacity:0.5;fill:%23FF7BAC;%7D .st5%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-linejoin:bevel;stroke-miterlimit:10;%7D .st7%7Bclip-path:url(%23SVGID_2_);%7D .st8%7Bfill:%23FFFFFF;%7D .st9%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:3;%7D .st10%7Bfill:%230000FF;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M23,3v14h-9h-1.44l-0.46,1.37L10.56,23H8v-4v-2H6H3V3H23 M25,1H1v18h5v6h6l2-6h11V1L25,1z'/%3E%3C/g%3E%3C/g%3E%3Crect x='7' y='9' width='2' height='2'/%3E%3Crect x='12' y='9' width='2' height='2'/%3E%3Crect x='17' y='9' width='2' height='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E ");

$icon-statistics: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bdisplay:inline;opacity:0.5;%7D .st2%7Bfill:%23FFD0FF;%7D .st3%7Bfill:%23FF7BAC;%7D .st4%7Bdisplay:inline;opacity:0.5;fill:%23FF7BAC;%7D .st5%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-linejoin:bevel;stroke-miterlimit:10;%7D .st7%7Bclip-path:url(%23SVGID_2_);%7D .st8%7Bfill:%23FFFFFF;%7D .st9%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:3;%7D .st10%7Bfill:%230000FF;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1'%3E%3Cpolyline class='st5' points='25,24 2,24 2,1 '/%3E%3Cpolyline class='st5' points='2,20 10,12 14,16 24,6 '/%3E%3C/g%3E%3C/svg%3E ");

$icon-tryout: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bdisplay:inline;opacity:0.5;%7D .st2%7Bfill:%23FFD0FF;%7D .st3%7Bfill:%23FF7BAC;%7D .st4%7Bdisplay:inline;opacity:0.5;fill:%23FF7BAC;%7D .st5%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-linejoin:bevel;stroke-miterlimit:10;%7D .st7%7Bclip-path:url(%23SVGID_2_);%7D .st8%7Bfill:%23FFFFFF;%7D .st9%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:3;%7D .st10%7Bfill:%230000FF;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1'%3E%3Cg%3E%3Cg%3E%3Cpath d='M23,9.13v13.85H3V9.13H23 M25,7.13H1v17.85h24V7.13L25,7.13z'/%3E%3C/g%3E%3Cpath class='st5' d='M13,7.29c-0.05-0.47-0.24-3.4-1.79-4.64c-1.29-1.04-3.16-0.83-4.2,0.46S6.17,6.27,7.46,7.3 C9.01,8.55,12.53,8.04,13,7.99V7.29z'/%3E%3Cpath class='st5' d='M13,7.29c0.05-0.47,0.24-3.4,1.79-4.64c1.29-1.04,3.16-0.83,4.2,0.46c1.04,1.29,0.84,3.16-0.45,4.2 C16.99,8.56,13.47,8.03,13,7.98V7.29z'/%3E%3Cline class='st5' x1='13' y1='8' x2='13' y2='25'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E ");

$icon-workout: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bdisplay:inline;opacity:0.5;%7D .st2%7Bfill:%23FFD0FF;%7D .st3%7Bfill:%23FF7BAC;%7D .st4%7Bdisplay:inline;opacity:0.5;fill:%23FF7BAC;%7D .st5%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-linejoin:bevel;stroke-miterlimit:10;%7D .st7%7Bclip-path:url(%23SVGID_2_);%7D .st8%7Bfill:%23FFFFFF;%7D .st9%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:3;%7D .st10%7Bfill:%230000FF;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1'%3E%3Cg%3E%3Cg%3E%3Cpath d='M23,5v18H3V5H23 M25,3H1v22h24V3L25,3z'/%3E%3C/g%3E%3Cline class='st5' x1='7' y1='7' x2='7' y2='1'/%3E%3Cline class='st5' x1='19' y1='7' x2='19' y2='1'/%3E%3Cline class='st5' x1='1' y1='10' x2='25' y2='10'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E ");

$icon-myactic: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bdisplay:inline;opacity:0.5;%7D .st2%7Bfill:%23FFD0FF;%7D .st3%7Bfill:%23FF7BAC;%7D .st4%7Bdisplay:inline;opacity:0.5;fill:%23FF7BAC;%7D .st5%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-linejoin:bevel;stroke-miterlimit:10;%7D .st7%7Bclip-path:url(%23SVGID_2_);%7D .st8%7Bfill:%23FFFFFF;%7D .st9%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-miterlimit:3;%7D .st10%7Bfill:%230000FF;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1'%3E%3Cg%3E%3Cline class='st5' x1='11' y1='10' x2='14' y2='2'/%3E%3Cg%3E%3Cpath d='M16.56,3l1.03,0.74L15.61,9h-5.23L8.41,3.74L9.44,3H16.56 M17.2,1H8.8L6,3l3,8h8l3-8L17.2,1L17.2,1z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cpath d='M13,11c3.31,0,6,2.69,6,6s-2.69,6-6,6s-6-2.69-6-6S9.69,11,13,11 M13,9c-4.42,0-8,3.58-8,8c0,4.42,3.58,8,8,8s8-3.58,8-8 C21,12.58,17.42,9,13,9L13,9z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");





$icon-myactic-primary: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bfill:none;stroke:%230133FF;stroke-width:2;stroke-miterlimit:10;%7D .st2%7Bfill:%230133FF;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1_1_'%3E%3Cg%3E%3Cline class='st1' x1='11' y1='10' x2='14' y2='2'/%3E%3Cg%3E%3Cpath class='st2' d='M16.56,3l1.03,0.74L15.61,9h-5.23L8.41,3.74L9.44,3H16.56 M17.2,1H8.8L6,3l3,8h8l3-8L17.2,1L17.2,1z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cpath class='st2' d='M13,11c3.31,0,6,2.69,6,6s-2.69,6-6,6s-6-2.69-6-6S9.69,11,13,11 M13,9c-4.42,0-8,3.58-8,8s3.58,8,8,8 s8-3.58,8-8S17.42,9,13,9L13,9z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");



$icon-booking-primary: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bfill:%230133FF;%7D .st2%7Bfill:none;stroke:%230133FF;stroke-width:2;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1_1_'%3E%3Cg%3E%3Cg%3E%3Cpath class='st1' d='M23,5v18H3V5H23 M25,3H1v22h24V3L25,3z'/%3E%3C/g%3E%3Cline class='st2' x1='7' y1='7' x2='7' y2='1'/%3E%3Cline class='st2' x1='19' y1='7' x2='19' y2='1'/%3E%3Cline class='st2' x1='1' y1='10' x2='25' y2='10'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E ");



$icon-statistics-primary: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bfill:none;stroke:%230133FF;stroke-width:2;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1_1_'%3E%3Cpolyline class='st1' points='25,24 2,24 2,1 '/%3E%3Cpolyline class='st1' points='2,20 10,12 14,16 24,6 '/%3E%3C/g%3E%3C/svg%3E%0A");



$icon-membership-primary: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bfill:%230133FF;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1_1_'%3E%3Cg%3E%3Cg%3E%3Cpath class='st1' d='M13,3c2.76,0,5,2.24,5,5s-2.24,5-5,5s-5-2.24-5-5S10.24,3,13,3 M13,1C9.13,1,6,4.13,6,8s3.13,7,7,7 s7-3.13,7-7S16.87,1,13,1L13,1z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st1' d='M13,15c4.83,0,8.87,3.44,9.8,8H3.2C4.13,18.44,8.17,15,13,15 M13,13C6.37,13,1,18.37,1,25h24 C25,18.37,19.63,13,13,13L13,13z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E ");



$icon-support-primary: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;%7D .st1%7Bfill:%230133FF;%7D%0A%3C/style%3E%3Cg id='MALL' class='st0'%3E%3C/g%3E%3Cg id='Layer_1_1_'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath class='st1' d='M23,3v14h-9h-1.44l-0.46,1.37L10.56,23H8v-4v-2H6H3V3H23 M25,1H1v18h5v6h6l2-6h11V1L25,1z'/%3E%3C/g%3E%3C/g%3E%3Crect x='7' y='9' class='st1' width='2' height='2'/%3E%3Crect x='12' y='9' class='st1' width='2' height='2'/%3E%3Crect x='17' y='9' class='st1' width='2' height='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E ");

