.background {
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

    &.top {
        background-position: top;
    }

    &.center {
        background-position: center center;
    }

    &.cover {
        background-size: cover;
    }

    &.pb-100 {
        padding-bottom: 100%;
    }

    &.top-image {
        height: 630px;
        background-blend-mode: multiply;
        position: relative;
    }

    &.big-carousel {
        padding-bottom: 100%;
    }
}

.container-article-image {
    padding-left: 0px;

    .background {

        &.article-image  {
            width: 100%;
            height: 188px;
            margin-bottom: 30px;
        }
    }
}

.container-big-carousel {
    padding: 0px;

    > .row {
        margin: 0px;

        > div {
            padding: 0px;
        }
    }
    
    .big-carousel {

        .background-big-carousel {
            padding-bottom: 100%;
        }

        h5 {
            padding-left: 15px;
        }
    
    }
}



@include media-breakpoint-up (md) {

    .background {

        &.top-image {
            height: 618px;
        }

        &.big-carousel {
            padding-bottom: 0px;
            height: 494px;
        }

    }

    .container-article-image {
        padding-left: 15px;
        padding-right: 15px;
    
        .background {
    
            &.article-image  {
                width: 100%;
                height: 267px;
            }
        }
    }

    .container-big-carousel {
        padding-left: 15px;
        padding-right: 15px;
    
        > .row {
            margin-top: calc(var(--bs-gutter-y) * -1);
            margin-right: calc(var(--bs-gutter-x) / -2);
            margin-left: calc(var(--bs-gutter-x) / -2);
    
            > div {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
        
        .big-carousel {
    
            .background-big-carousel {
                padding-bottom: 0px;
                height: 267px;
            }

            h5 {
                padding-left: 0px;
            }
        
        }
    }
}


@include media-breakpoint-up (xxl) {
  
    .background {

        &.top-image {
            height: 719px;
        }

        &.article-image  {
            height: 495px;
            width: calc(100% - ((100vw - 1320px) + 0px));
        }

        &.big-carousel {
            padding-bottom: 0px;
            height: 494px;
        }
    }

    .container-article-image {
    
        .background {
    
            &.article-image  {
                height: 494px;
            }
        }
    }

    .container-big-carousel {
        
        .big-carousel {
    
            .background-big-carousel {
                height: 494px;
            }
        }
    }   
}
