.input-group {

  align-items: center;
  &.h-auto {
    height: auto;
    min-height: 28px;
    padding: 10px 0;
  }

  &.border-bottom-color-sand {
    border-bottom: 1px solid $color-sand;
  }
}

.group-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 54px;
}

@include media-breakpoint-up(xxl) {

    .input-group {

      &.h-auto {
        height: auto;
        min-height: 28px;
        padding: 12px 0;
      }
    }
    
    .group-column {
      height: 62px;
    }
}
