.list-radio-label {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.list-radio {
    margin-right: 15px;
    top: 13px;
    position: relative;
}

.list-radio-description {
    font-size: 13px;
}
