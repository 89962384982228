.slideupbox {
    z-index: 999;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: $color-white;
}

.wrapper-slide-top {
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: -1;
    margin-top: -1px;
  

    h3 {
        margin-bottom: 0px;
    }

}

.wrapper-slide-content {
    padding-top: 10px;
    padding-bottom: 20px;
    z-index: 2;
    background-color: $color-white;

    .price-basket {
        @include headline-one(xxs);
        margin-bottom: 0px;
    }
}



@include media-breakpoint-up(xxl) {
    .slideupbox {
        border-bottom: none
    }

    .wrapper-slide-content {
        padding-top: 0px;

        .price-basket {
            @include headline-two(xxl);
            margin-bottom: 0px;
        }
    }

    .wrapper-slide-bottom {
        padding-top: 0px;
    }
}