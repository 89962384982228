.wrapper-update-membership {
    padding-bottom: 154px;
    margin-top: 20px;

    //.inactive {
    //    opacity: 0.5;
    //}
}

@include media-breakpoint-up(xxl) {
    .wrapper-update-membership {
        padding-bottom: 200px;
        margin-top: 30px;
    }
}