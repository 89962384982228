.schedule {
    margin-bottom: 50px;
    
    &.my-bookings {
        .slick-list {
            margin-top: 20px;
        }
        .slick-track{ 
            margin-left: 0; 
        }
    }

    .day-container {
        padding-left: 7.5px;
        padding-right: 7.5px;
        margin-bottom: 30px;
    }

    .day-header {
        margin-top: 5px;
    }

    .class-info {
        padding-top: 5px;
        border-radius: 3px;
        background-color: #f2f2f2;
        margin-bottom: 14px;
        position: relative;
        padding-left: 14px;
        padding-right: 18px;
        position: relative;
        cursor: pointer;
        height: 175px;

        > div {

            span {
                display: block;
            }

            .start-time {
                display: block;
            }

            .duration {
                display: block;
            }

            .center-name {
                display: block;
                color: $color-rock;
            }
        }

        &.unavailable-max-booked {
            opacity: 0.4;
        }

        &.booking-closed {
            background-color: $color-sand;

            .start-time,
            .duration,
            .center-name,
            .class-name {
                opacity: 0.4;
            }
            
            &:before {
                opacity: 0.4;
            }
            
        }

        &.booking-locked {
            background-color: $color-sand;

            .start-time,
            .duration,
            .center-name,
            .class-name {
                opacity: 0.4;
            }
            
            &:before {
                opacity: 0.4;
            }
            
        }

        &.booked {
            background-color: $color-stone;
            &:before {
                content: "";
                position: absolute;
                left: 2px;
                width: 3px;
                top: 2px;
                bottom: 2px;
                border-radius: 1.5px;
                background-color: $color-primary;
            }
            
            
            //.duration,
            //.center-name {
            //    color: $color-stone;
            //}
        }

        &:before {
            content: "";
            position: absolute;
            left: 1px;
            width: 3px;
            top: 2px;
            bottom: 2px;
            border-radius: 1.5px;
            background-color: $color-primary;
        }

        p {
            margin-top: 0px;
            margin-bottom: 0px;
        }

     

        .class-name {
            margin-bottom: 0px;
             margin-bottom: 0px;
             line-height: 25px;
             height: 50px;
             overflow: hidden;
             text-overflow: ellipsis;
             -webkit-line-clamp: 2;
             -webkit-box-orient: vertical;
             display: -webkit-box;
         }

        .day-name {
           margin-bottom: 0px;
            margin-bottom: 10px;
            height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
        }


        .indicator {
            position: relative;
            padding-left: 24px;
            &:before {
                content: "";
                position:absolute;
                left:0;
                width: 19px;
                height: 19px;
                font-size: 19px;
                line-height: 19px;
                border-radius: 50%;
            }
            &.available {
                
                &:before {
                    color: $color-primary;
                    font-family: "Material Icons";
                    content: "radio_button_unchecked";
                }
            }
            &.booked {

                &:before {
                    background-color: $color-white;
                    color: $color-primary;
                    font-family: "Material Icons";
                    content: "check";
                }

            }
            &.prebookingfull {
                &:before {
                    color: $color-attention;
                    font-family: "Material Icons";
                    content: "highlight_off";
                }
            }
            &.queue {
                &:before {
                    color: $color-primary;
                    font-family: "Material Icons";
                    content: "watch_later";
                }
            }

            &.b-closed {
                &:before {
                    color: $color-rock;
                    font-family: "Material Icons";
                    content: "nightlight_round";
                }

                p {
                    display: none;
                }
            }

            &.b-locked {
                &:before {
                    color: $color-rock;
                    font-family: "Material Icons";
                    content: "lock";
                }

                p {
                    display: none;
                }
            }
            
            p {
                font-family: $helvetica-regular;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                line-height: 19px;
                white-space: nowrap;
            }
            
        }

        
    }

    .slick-next {
        right: 0;
    }

    .slick-prev {
        left: 0;
    }

    .slick-arrow {
        top: 0px;
        
        &::before {
            width: 19px;
            height: 19px;
            font-size: 16px;
            line-height: 19px;
            color: $color-white;
            background-color: $color-black;
            border-radius: 50%;
            font-family: "Material Icons";
        }
    }

    .slick-prev:before {
        content: "chevron_left";
    }

    .slick-next:before {
        content: "chevron_right";
    }

  


    .slick-dots {
        height: 44px;
        line-height: 44px;
        margin-bottom: 10px;
        
        li {
            margin: 0px;
            button {
                width: 20px;
                height: 20px;

                &:hover {
                    color: $color-black;
                    opacity: 1;
                }
            }
            button:before {
                color: $color-stone;
                font-size: 10px;
                opacity: 1;
            }
        }
        li.slick-active {
            button:before {
                color: $color-black;
                opacity: 1;
            }
        }
    }
}

@include media-breakpoint-up(sm) {

    .schedule {
        .slick-arrow {
            top: -25px;
        }
        .slick-list {
            margin-top: 60px;
        }

        &.my-bookings {
            .slick-list {
                margin-top: 20px;
            }
        }
    }
}