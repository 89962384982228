.border-card {
  border: 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding:10px;
      margin-bottom: 20px;
}


.list {
  padding: 0px;
  list-style: none;
  margin-bottom: 0px;

    > li > div {
       
    }
    &.small > li > div {
        min-height: 40px;
    }

    &.medium > li > div {
     min-height: 1px;

    }

    &.big > li > div {
        min-height: 60px;
    }

 


    > li {
       // border-bottom: 1px solid $color-sand;

        > div {
            // This div wraps the entire content of a list item
            display: flex;
            align-items: center;
            justify-content: space-between;
            //flex-wrap: wrap;
            flex-wrap: nowrap;

            > div {
                // This div is the left or right
                // column of a list item
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-grow: 1;
                position: relative;
                color: $color-black;

                &.icon {
                    flex-grow: 0;
                }
            }
        }

    }
}

.points-table {
  line-height: normal;

  .points-row {


    .points-table-dot {
      margin-right: 10px;
      flex-grow: 0;
    }

    .points-table-name {
      width: 60%;
      flex-grow: 1;
    }

    .points-table-nr {
      width:fit-content;
    }
  }

  .dot {
    margin-top: 5px;
    border-radius: 50px;
    height: 19px;
    width: 19px;
  }
}