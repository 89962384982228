.myactic-benefits {
    position: relative;

    .benefit-title {
        padding-left: 40px;
    }

    .list {
        
        li {
            position: relative;
            //padding-left: 30px;

            > div {
                //margin-left: 10px;
            }

            &:before {
                content: "";
                left: 10px;
                width: 25px;
                height: 25px;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center center;
                display: block;
                background-size: contain;
            }

            &.invite {
                
                &:before {
                    background-image: url(./../../assets/images/benefits-icons/invite.svg);
                }
            }

            &.bia {
                
                &:before {
                    background-image: url(./../../assets/images/benefits-icons/bia.svg);
                }
            }
            &.freeze {
                
                &:before {
                    background-image: url(./../../assets/images/benefits-icons/freeze.svg);
                }
            }

            &.pt {
                
                &:before {
                    background-image: url(./../../assets/images/benefits-icons/pt.svg);
                }
            }
            &.instructor {
                
                &:before {
                    background-image: url(./../../assets/images/benefits-icons/instructor.svg);
                }
            }
            &.max {
                
                &:before {
                    background-image: url(./../../assets/images/benefits-icons/max.svg);
                }
            }
            &.ptextra {
                
                &:before {
                    background-image: url(./../../assets/images/benefits-icons/pt.svg);
                }
            }
            &.partner {
                
                &:before {
                    background-image: url(./../../assets/images/benefits-icons/partner.svg);
                }
            }

            &.bringfriend {
                
                &:before {
                    background-image: url(./../../assets/images/benefits-icons/bring-a-friend.svg);
                }
            }
        }
    }
}
.benefit-wrapper {
    display: block;

    > p {
        margin-bottom: 30px;
        margin-top: 30px;
    }

   
    img {
        max-height: 150px;
    }
}
