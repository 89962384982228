.dropdown {
  position: relative;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  > label {
    @include body-text-regular(xs);
    padding: 20px 10px 20px 10px;
    border-radius: 6px;
    border: 1px solid $color-stone;
    background-color: $color-input;
    width: 100%;
    color: $color-black;
    text-align: start;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    &:after {
      content: "unfold_more";
      font-family: "Material Icons";
      color: $color-primary;
      border: 0 none;
      position: absolute;
      right: 5px;
      transform: translateY(-50%);
      top: 50%;
    }
  }
  &.ellipsis {
    display: inline-block;
    > label {
      display: inline-block;
      border: 0 none;
      &:before {
        content: "";
        display: none;
      }
    }
  }
  &.active > span:before {
    content: "\f106";
  }
}
.dropdown {
  > div {
    position: absolute;
    z-index: 999;
    background-color: #ffffff;
    box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    max-height: calc(100vh - 70px);
    overflow: auto;
    border: 0 none;
    border-radius: 5px;
    border: 1px solid rgba($blue, 0.2);
    width: auto;
    height: auto;
    padding: 5px 0px;
    text-align: left;
    > i {
      width: 100%;
    }
  }
  &.dropdown-right > div {
    right: 0;
  }
  &.dropdown-up > div {
    bottom: 0;
  }
}

.dropdown > div > ul {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  list-style: none;
  padding: 0;
  li {
    text-align: left;
    white-space: nowrap;
    line-height: 30px;
    cursor: pointer;
    //color: $dark;
    padding: 0 10px;
    flex-basis: 100%;
    font-size: 16px;
    position: relative;

    > div {
      // Submenu
      position: absolute;
      left: 95%;
      top: -0.5em;
      z-index: 20;
      background-color: #ffffff;
      box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      text-align: left;

      @include body-text();
      width: 100%;
      border-radius: 7px;
      padding: 6px 27px 11px 6px;
      background-color: $color-rock;
      border: solid 0.5px rgba(104, 124, 144, 0.3);
      overflow: scroll;
      max-height: 300px;
    }

    &:hover {
      background-color: $color-sand;
    }
 

    &.active, &.selected {
      font-family: $hansen-bold;
      p, span, div {
        font-family: $hansen-bold;
      }
    }

    &.inactive {
      cursor: default;
      background-color: #ffffff;
      span {
        opacity: 0.5;
        cursor: default;
      }
    }

    &.readonly {
      cursor: default;
      background-color: #ffffff;
      span {
        opacity: 0.5;
        cursor: default;
      }
    }
   
  }
}


@include media-breakpoint-up(md) {
  .dropdown {
      label {
          @include body-text-regular(md);
      }
  }
}

@include media-breakpoint-up(xxl) {
  .dropdown {
      label {
          @include body-text-regular(xxl);
      }
      ul {
          @include body-text-regular(xxl);
          li {
              a,
              span {
                  @include body-text-regular(xxl);
              }
          }
      }
  }
}