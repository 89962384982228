@mixin headline-one($screensize: xs) {
	margin: 0 0 20px 0;
	font-family: $hansen-bold;
	@if $screensize == xxl {
		font-size: 50px;
		letter-spacing: -1px;
		line-height: 60px;
	}  @else if $screensize == md {
		font-size: 46px;
		letter-spacing: -1px;
		line-height: 54px;
	}  @else {
		font-size: 34px;
		letter-spacing: 0.31px;
		line-height: 44px;
		letter-spacing: -1px;
	}
}


// H2
@mixin headline-two($screensize: xs) {
	margin: 0 0 15px 0;
	font-family: $hansen-bold;
	@if $screensize == xxl {
		font-size: 30px;
		letter-spacing: -1px;
		line-height: 40px;
	} @else if $screensize == md {
		font-size: 28px;
		letter-spacing: 0px;
		line-height: 38px;
	} @else {
		font-size: 24px;
		letter-spacing: -0.5px;
		line-height: 28px;
	}
}

// H3
@mixin headline-three($screensize: xs) {
	font-family: $hansen-bold;
	margin: 0 0 5px 0;
	@if $screensize == xxl {
		font-size: 23px;
		letter-spacing: -0.5px;
		line-height: 30px;
	} @else if $screensize == md {
		font-size: 19px;
		letter-spacing: -1px;
		line-height: 29px;
	} @else {
		font-size: 17px;
		letter-spacing: -0.5px;
		line-height: 25px;
	}
}

// H4
@mixin headline-four($screensize: xs) {
	margin: 0 0 10px 0;
	font-family: $hansen-bold;
	@if $screensize == xxl {
		font-size: 16px;
		letter-spacing: 0px;
		line-height: 22px;
	} @else if $screensize == md {
		font-size: 14px;
		letter-spacing: 0px;
		line-height: 20px;
	} @else {
		font-size: 13px;
		letter-spacing: 0px;
		line-height: 18px;
	}
}

// H5 caption
@mixin headline-five($screensize: xs) {
	font-family: $hansen-bold;
	text-transform: uppercase;
	margin: 0 0 20px 0;
	@if $screensize == xxl {
		font-size: 13px;
		letter-spacing: 1px;
		line-height: 26px;
	} @else if $screensize == md {
		font-size: 12px;
		letter-spacing: 0.5px;
		line-height: 27px;
	}  @else {
		font-size: 10px;
		letter-spacing: 1px;
		line-height: 18px;
	}
}

// H6 TAG
@mixin headline-six($screensize: xs) {
	margin: 0 0 0px 0;
	text-transform: uppercase;
	font-family: $hansen-bold;
	@if $screensize == xxl {
		font-size: 13px;
		letter-spacing: 1px;
	} @else if $screensize == md {
		letter-spacing: 0.5px;
		font-size: 12px;
	}  @else {
		font-size: 10px;
		letter-spacing: 1px;
	}
}

h1,
.h1, .h1 span {
	@include headline-one();
}

h2,
.h2, .h2 span {
	@include headline-two();
}

h3,
.h3, .h3 span {
	@include headline-three();
}

h4,
.h4, .h4 span {
	@include headline-four();

	&.bold {
		font-family: $hansen-bold;
	}
}

h5,
.h5, .h5 span {
	@include headline-five();
}

h6,
.h6, .h6 span {
	@include headline-six();
}


@include media-breakpoint-up(md) {
	h1,
	.h1, .h1 span {
		@include headline-one(md);
	}

	h2,
	.h2, .h2 span {
		@include headline-two(md);
	}

	h3,
	.h3, h3 span {
		@include headline-three(md);
	}

	h4,
	.h4, .h4 span {
		@include headline-four(md);

		&.bold {
			font-family: $hansen-bold;
		}
	}

	h5,
	.h5, .h5 span {
		@include headline-five(md);
	}

	h6,
	.h6, .h6 span {
		@include headline-six(md);
	}
}


@include media-breakpoint-up(xxl) {
	h1,
	.h1, .h1 span {
		@include headline-one(xxl);
	}

	h2,
	.h2, .h2 span {
		@include headline-two(xxl);
	}

	h3,
	.h3, .h3 span {
		@include headline-three(xxl);
	}

	h4,
	.h4, .h4 span {
		@include headline-four(xxl);

		&.bold {
			font-family: $hansen-bold;
		}
	}

	h5,
	.h5, .h4 span {
		@include headline-five(xxl);
	}

	h6,
	.h6, .h6 span {
		@include headline-six(xxl);
	}
}


@mixin preamble-text($screensize: xs) {
	font-family: $helvetica-light;
	margin: 0 0 15px 0;
	@if $screensize == xxl {
		font-size: 20px;
		letter-spacing: 0px;
		line-height: 30px;
	} @else if $screensize == md {
		font-size: 20px;
		letter-spacing: 0px;
		line-height: 30px;
	} @else {
		font-size: 20px;
		letter-spacing: 0px;
		line-height: 30px;
	}
}

@mixin body-text($screensize: xs) {
	font-family: $helvetica-regular;
	@if $screensize == xxl {
		font-size: 16px;
		letter-spacing: 1px;
		line-height: 22px;
	} @else if $screensize == md {
		font-size: 16px;
		letter-spacing: 0.5px;
		line-height: 22px;
	}  @else {
		font-size: 16px;
		letter-spacing: 0.5px;
		line-height: 22px;
	}
}

@mixin body-text-regular($screensize:xs) {
	font-family: $hansen-regular;
	@if $screensize == xxl {
		font-size: 16px;
		letter-spacing: 0px;
		line-height: 22px;
	} @else if $screensize == md {
		font-size: 16px;
		letter-spacing: 0px;
		line-height: 22px;
	}  @else {
		font-size: 16px;
		letter-spacing: 0px;
		line-height: 22px;
	}
}

@mixin body-text-regular-small($screensize:xs) {
	font-family: $hansen-regular;
	@if $screensize == xxl {
		font-size: 15px;
		letter-spacing: 0px;
		line-height: 22px;
	} @else if $screensize == md {
		font-size: 15px;
		letter-spacing: 0px;
		line-height: 23px;
	}  @else {
		font-size: 14px;
		letter-spacing: 0px;
		line-height: 18px;
	}
}

@mixin body-text-small($screensize: xs) {
	font-family: $helvetica-regular;
	@if $screensize == xxl {
		font-size: 13px;
		letter-spacing: 0.75px;

	} @else if $screensize == md {
		font-size: 13px;
		letter-spacing: 0.75px;

	}  @else {
		font-size: 13px;
		letter-spacing: 0.75px;
		line-height: 21px;
	}
}

@mixin caption-text($screensize: xs) {
	font-family: $helvetica-regular;
	margin-bottom: 10px;
	@if $screensize == xxl {
		font-size: 13px;
		letter-spacing: 0.75px;
		line-height: 16px;
	} @else if $screensize == md {
		font-size: 11px;
		letter-spacing: 0.75px;
		line-height: 15px;
	}  @else {
		font-size: 11px;
		letter-spacing: 0.75px;
		line-height: 15px;
	}
}

@mixin caption-text-regular($screensize: xs) {
	font-family: $hansen-regular;
	margin-bottom: 10px;
	@if $screensize == xxl {
		font-size: 12px;
		line-height: 16px;
	} @else if $screensize == md {
		font-size: 12px;
		line-height: 18px;
	}  @else {
		font-size: 11px;
		line-height: 15px;
		letter-spacing: normal;
	}
}


section,
p,
span,
em,
b,
i,
.body-text {
	@include body-text(xs);
	span,
	em,
	b,
	i {
		font-size: inherit;
		line-height: inherit;
		letter-spacing: inherit;
		font-family: inherit;
	}
}

p.body-text-small,
.body-text-small,
.text-small {
	@include body-text-small(xs);
}

p.preamble-text,
.preamble-text {
	@include preamble-text(xs);
}

.caption-text {
	@include caption-text(xs);
	//margin: 0;
	line-height: 1.5em;
	color: $color-rock;

	&.bold {
		font-family: $hansen-bold;
	}
}

.pre-line {
	white-space: pre-line;
}

.caption-text-regular {
	@include caption-text-regular(xs);
}

.body-text-regular {
	@include body-text-regular(xs);
}

.body-text-regular-small {
	@include body-text-regular-small(xs);
}



@include media-breakpoint-up(md) {
	section,
	p,
	span,
	em,
	b,
	i,
	.body-text {
		@include body-text(md);
	}

	p.body-text-small,
	.body-text-small,
	.text-small {
		@include body-text-small(md);
	}

	p.preamble-text,
	.preamble-text {
		@include preamble-text(md);
	}

	.caption-text {
		@include caption-text(md);
	}
	
	.caption-text-regular {
		@include caption-text-regular(md);
	}

	.body-text-regular {
		@include body-text-regular(md);
	}

	.body-text-regular-small {
		@include body-text-regular-small(md);
	}
}
	

@include media-breakpoint-up(xxl) {
	section,
	p,
	span,
	em,
	b,
	i,
	.body-text {
		@include body-text(xxl);
	}

	p.body-text-small,
	.body-text-small,
	.text-small {
		@include body-text-small(xxl);
	}

	p.preamble-text,
	.preamble-text {
		@include preamble-text(xxl);
	}

	.caption-text {
		@include caption-text(xxl);
	}

	.caption-text-regular {
		@include caption-text-regular(xxl);
	}

	.body-text-regular {
		@include body-text-regular(xxl);
	}

	.body-text-regular-small {
		@include body-text-regular-small(xxl);
	}
	
}
