


.barmenu {
  position: fixed;
  bottom: 0;
  z-index: 120;
  width: 100%;
  height: 71px;
  background: #FBFBFB;
  backdrop-filter: blur(27.2px);
  // background-color: rgba(247, 247, 247, 0.5);
  display: flex;
  align-items: stretch;
  > div, > a {
    text-align: center;
    display: block; 
    position: relative;
    flex-grow: 1;
    cursor: pointer;
    //color: $color-rock;
     

    &::before {
      content: "";
      height: 24px;
      background-repeat: no-repeat;
      background-position: center center;
      margin-top: 5px;
      width: 100%;
      display: block;
      color: $color-black;
    }
    &.inactive {
      cursor: default;
      &::before {
        color: $color-stone;
        opacity: 0.2;
      }
    }

    &.barmenu-myactic::before {
      background-image: $icon-myactic;
    }

    &.barmenu-booking::before {
      background-image: $icon-workout;
    }

    &.barmenu-statistics::before {
      background-image: $icon-statistics;
    }

    &.barmenu-membership::before {
      background-image: $icon-mypages;
    }

    &.barmenu-support::before {
      background-image: $icon-service;
    }

    &.barmenu-myactic {

      &.active {

        &::before {
          background-image: $icon-myactic-primary;
        }
      }
    }

    &.barmenu-booking {

      &.active {

        &::before {
          background-image: $icon-booking-primary;
        }
      }
    }

    &.barmenu-statistics {

      &.active {

        &::before {
          background-image: $icon-statistics-primary;
        }
      }
    }

    &.barmenu-membership {

      &.active {

        &::before {
          background-image: $icon-membership-primary;
        }
      }
    }

    &.barmenu-support {

      &.active {

        &::before {
          background-image: $icon-support-primary;
        }
      }
    }
    &.active::before {
      color: $color-primary;
    }


    span {
      position: absolute;
      top: 35px;
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.16px;
      text-align: center;
      word-wrap: none;
      overflow: hidden;
      width: 100%;
      display: block;

      color: $color-black;
      transition: color ease-out 100ms;
    }
    &.active span:last-child {
      color: $color-rock;
    }
    &.inactive span:last-child {
      color: $color-stone;
    }
  }
}

 @include media-breakpoint-up (md) {
  .barmenu, .slideup {
    display: none;
  }
 }