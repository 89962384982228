.pull-content {
  margin-top: -54px;
}
.main-pt {
  padding-top: 54px;
}

@include media-breakpoint-up (sm) {

  
}

@include media-breakpoint-up (md) {
  
}

@include media-breakpoint-up (lg) {

  
}

@include media-breakpoint-up (xl) {

 
}

@include media-breakpoint-up (xxl) {
  .pull-content {
      margin-top: -90px;
  }
 
}