.topmenu {
  bottom: 0;
  z-index: 120;
  width: 100%;
  justify-content: center;

  > div,
  > a {
    cursor: pointer;
    flex-wrap: wrap;
    width: percentage(math.div(1, 5));
    position: relative;

    

    &.inactive {
      cursor: default;
      &::before {
        color: $color-stone;
        opacity: 0.2;
      }
    }

  

    

    &.active::before {
      color: $color-primary;
    }

    span {
      bottom: 0;
      font-size: 12px;
      letter-spacing: 0px;
      font-family: $hansen-regular;
      text-align: center;
      word-wrap: none;
      overflow: hidden;
      width: 100%;
      display: block;
      color: $color-black;
      transition: color ease-out 100ms;
    }
    &.active span {
      color: $color-primary;
    }
    &.inactive span {
      color: $color-stone;
    }
  }
}

.avatar {
  width: 32px !important;
  height: 32px;
  background-color: #F0957F;
  border-radius: 50%;


  .avatar-text {
    display: block;
    line-height: 32px;
    font-family: $hansen-bold !important;
    color: $color-white;
    font-size: 10px;
    text-align: center;
    user-select:none;

  }
}

@include media-breakpoint-up(lg) {

  .avatar {
    margin-right: 20px;

  }
 
}

@include media-breakpoint-up(xl) {
  .topmenu {
    > div,
    > a {
      &::before {
        height: 30px;
      }

      span {
        font-size: 12px;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .topmenu {
    > div,
    > a {
      &::before {
        height: 35px;
      }
    }
  }
}
