.wrapper-settings {
    padding-bottom: 30px;
}

/* EFT image preview. */
.eft-sign-pdf-preview {
    cursor: zoom-in;
    overflow: scroll;
}
.eft-sign-pdf-preview > img.pdf-preview {
    max-width: 100%;
}

.eft-sign-pdf-preview:not(.loaded) > img.pdf-preview {
    display: none;
}
.eft-sign-pdf-preview:not(.loaded) > div.loading-wrapper {
    display: block;
}

.eft-sign-pdf-preview.loaded > div.loading-wrapper {
    display: none !important;
}

.eft-sign-pdf-preview.zoom.zoom-1 > img.pdf-preview {
    max-width: 201%;
}
.eft-sign-pdf-preview.zoom.zoom-2 > img.pdf-preview {
    max-width: 300%;
}
.eft-sign-pdf-preview.zoom.zoom-3 > img.pdf-preview {
    max-width: none;
}

@include media-breakpoint-up(lg) { 
    .eft-sign-pdf-preview.zoom.zoom-1 > img.pdf-preview {
        max-width: 125%;
    }
    .eft-sign-pdf-preview.zoom.zoom-2 > img.pdf-preview {
        max-width: 150%;
    }
    .eft-sign-pdf-preview.zoom.zoom-3 > img.pdf-preview {
        max-width: none;
    }
}