.color-myactic-blue {
  color: $color-myactic-blue;
}
.color-myactic-green {
  color: $color-myactic-green;
}
.color-myactic-pink {
  color: $color-myactic-pink;
}
.color-myactic-turquoise {
  color: $color-myactic-turquoise;
}
.color-myactic-yellow {
  color: $color-myactic-yellow;
}
.background-myactic-blue {
  background-color: $color-myactic-blue;
}
.background-myactic-green {
  background-color: $color-myactic-green;
}
.background-myactic-pink {
  background-color: $color-myactic-pink;
}
.background-myactic-turquoise {
  background-color: $color-myactic-turquoise;
}
.background-myactic-yellow {
  background-color: $color-myactic-yellow;
}

.myactic-wrapper {
  display: flex;
  flex-wrap: wrap;
  background: $color-rock;
  > div {
    width: 100%;
  }
}

@include media-breakpoint-up(md) {

}

.myactic-points {
  position: relative;
  padding: 0 0 0px 0;


  .bar-wrapper {
    border-bottom: 1px solid $color-rock;

    .bar {
      margin-top: 5px;
      width: 100%;
      padding-top: 1px;
      padding-bottom: 1px;
      padding-left: 1px;
      padding-right: 1px;
      overflow: hidden;
      border: solid 0.5px rgba(51, 51, 153, 0.3);
      border-radius: 5px;
      background-color: rgba(51, 51, 153, 0.05);
    }

    .bar-content {
      height: 100%;
      float: left;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius:4px;

      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius:4px;
      }
    }

    .bar-animation {
      height: inherit;
      display: flex;
      height: 8px;
      webkit-animation: expandWidth 3s 1s forwards;
      -moz-animation: expandWidth 3s 1s forwards;
      -ms-animation: expandWidth 3s 1s forwards;
      -o-animation: expandWidth 3s 1s forwards;
      animation: expandWidth 3s 1s forwards;

      
    }

    @-webkit-keyframes expandWidth {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }
    @-moz-keyframes expandWidth {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }
    @-ms-keyframes expandWidth {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }
    @-o-keyframes expandWidth {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }
    @keyframes expandWidth {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }

    
  }


  .coin {
    height: 20px;
    width: 20px;
    display: inline-block;
    margin: 0px;
    margin-left: 10px;
    background-image: url(./../../assets/images/coin-smiley.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .points-total {
    display: flex;
    justify-content: space-between;
    
    p {
      line-height: 20px;
    }
    
  }
}
