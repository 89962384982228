.form-checkbox {
  
  .form-check-input {
    border-radius: 50%;
    border: 2px solid $color-primary;
    height: 19px;
    width: 19px;
    min-width: 19px;
  }

  a {
    color: $color-primary;
    text-decoration: none;
    margin-left: 4px;
  }

  input {
    width: 18px;
    height: 18px;
  }
  

  .form-check-input:focus {
    box-shadow: none;
  }

  .form-check-input:checked {
    background-color: #ffffff;
    background: transparent;
    border: 2px solid $color-primary;
  }

  .form-check-input[type="radio"],
  .form-check-input[type="checkbox"] {
    content: "";
    background: transparent;
    border: 2px solid $color-primary;
  }

  .form-check-input:checked[type="radio"],
  .form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='2' fill='%232abfc9'/%3e%3c/svg%3e");
  }
}