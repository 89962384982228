a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.link {


    &:hover {
        text-decoration: none;
    }

    &.color-primary {
        color: $color-primary;

        &:hover {
            color: $color-primary;
        }
    }

    &.color-black {
        color: $color-black;

        &:hover {
            color: $color-black;
        }
    }

    &.color-black {
        color: $color-black;

        &:hover {
            color: $color-black;
        }
    }

    &.color-white {
        color: $color-white;

        &:hover {
            color: $color-white;
        }
    }
}