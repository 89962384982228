.achievements-wrapper {
  position: relative;
  overflow: auto;

  .achi-progress {
    display: flex;
    padding: 1px 1px 1px 1px;
    border-radius: 5px;
    border: solid 0.5px rgba(51, 51, 153, 0.3);
    background-color: rgba(51, 51, 153, 0.05);
    width: 80px;
    margin-left: auto;
    span {
      display: block;
      height: 8px;
      background-color: $color-myactic-turquoise;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  li .loading {
    .ripple {
      width: 19px;
      height: 19px;
    }
    div {
      width: 19px;
      height: 19px;
    }
  }

  .achi-get {
    margin-left: 10px;
    flex-grow: 0;
      span:before {
        width: 19px; 
        display: block;
        font-family: "Material Icons";
        content: "get_app";
        font-size: 19px;
        color: $color-rock;
        cursor: pointer;
    }
  }

  .retreiving {
    opacity: 0.5;
    .achi-get {
      span:before {
        cursor: default;
      }
    }
  }
}