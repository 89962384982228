

.p-end {
  margin-bottom: 20px;
  content: " ";
  display: block;
}

.mb-15-30 {
  margin-bottom: math.div($gutter-1, 2);
}

.mt-small {
  margin-top: $gutter-1;
}
.mb-small {
  margin-bottom: $gutter-1;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-small {
  margin-top: $gutter-1;
}

.mb-30 {
  margin-bottom: $gutter-1;
}

.h-50-d-flex {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


@include media-breakpoint-up (sm) {

  
}

@include media-breakpoint-up (md) {

  
}

@include media-breakpoint-up (lg) {

  
}

@include media-breakpoint-up (xl) {

 
}

@include media-breakpoint-up (xxl) {

  .mb-15-30 {
      margin-bottom: $gutter-1;
  }

  .mb-small {
      margin-bottom: $gutter-2;
  }

  .mt-small {
      margin-top: $gutter-2;
  }
 
}