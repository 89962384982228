.cursor-pointer {
  cursor: pointer;
}

.text-blue {
  color: #0033FF;
}

.text-bold {
  font-weight: bold;
}