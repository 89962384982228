$spacer: 1rem;
$grid-gutter-width: 1rem;
$container-padding-x: 1.5rem;

$rem: 10px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1320px
);


// Bootstrap variables
$grid-gutter-width: 1rem;
$container-padding-x: 1.5rem;

// Custom variables
$rem: 10px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1320px
);

$color-white: #ffffff;
$color-black: #000000;

$color-primary: #0133ff;
$color-rock: #617594;
$color-stone: #a1b1c8;
$color-sand: #d9d8d6;
$color-attention: #b1144e;
$color-input: #f7f8f9;

$color-myactic-blue: #333399;
$color-myactic-turquoise: #2ABFC9;
$color-myactic-green: #97D205;
$color-myactic-yellow: #FFE900;
$color-myactic-pink: #E83C84;
$color-myactic-red: red;
$color-light-sand : #f2f2f2;
$input-bg-color: #f7f8f9;

$gutter-1: 30px;
$gutter-2: 60px;
$gutter-3: 90px;
$gutter-4: 100px;


//btn font

$hansen-bold: "hansen-grotesque-bold", sans-serif;
$hansen-regular: "hansen-grotesque-regular" , sans-serif;;

//h1, h2
$headline-font: $hansen-bold;

//Body
$helvetica-regular: "helvetica-regular", sans-serif;
$helvetica-light: "helvetica-light", sans-serif;
$helvetica-bold: "helvetica-bold", sans-serif;


