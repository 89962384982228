.form-checkbox,
.form-check-switch {
    
    label {
        @include body-text-small(xs);
    
        a {
            color: $color-primary;
    
            &:hover {
                color: $color-primary;
                text-decoration: none;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .form-checkbox,
    .form-check-switch {
    
        label {
            @include body-text-small(md);   
        }
    }
}

@include media-breakpoint-up(xxl) {
    .form-checkbox,
    .form-check-switch {
    
        label {
            @include body-text-small(xxl);   
        }
    }
}
