.list {
    .icon span:after {
        font-family: "Material Icons";
        color: $color-primary;
        padding-left: 5px;
        vertical-align: middle;
        font-size: 30px;
    }

    .icon.toggle-down span:after {
        content: "keyboard_arrow_down";
    }

    .icon.toggle-up span:after {
        content: "keyboard_arrow_up";
    }

    .icon.chevron-right span:after {
        content: "chevron_right";
    }

    .icon.mail span:after {
        content: "mail";
    }

    .icon.phone span:after {
        content: "phone";
    }

    .icon.download span:after {
        content: "get_app";
    }

    &.list-toggle {
      
        li {
            border: 1px solid rgba(0, 0, 0, 0.15);
            padding: 20px 10px;
            margin-bottom: 20px;
            border-radius: 4px;
        }


        li > div:first-child {
            cursor: pointer;
        }

        .link-download-invoice {
            font-family: $hansen-regular;
            letter-spacing: 0px;
            @include body-text-small(xxs);
            
            &:after {
                font-family: "Material Icons";
                color: $color-primary;
                padding-left: 10px;
                vertical-align: middle;
                font-size: 19px;
                content: "get_app";
            }
        }
    }

    &.list-link {
        li {
            cursor: pointer;

            a {
                color: $color-primary;
                letter-spacing: -0.41px;
                font-size: 17px;
                font-family: $helvetica-regular;
                line-height: 22px;
            }
        }
    }

    &.list-achievements {
        li {
            position: relative;
            padding-left: 30px;

            &:before {
                content: "";
                left: 0%;
                top: 50%;
                transform: translateY(-50%);
                width: 19px;
                height: 19px;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center center;
                display: block;
                background-image: $icon-myactic;
            }
        }
    }

    .more-info {
        display: block;

        a:not(.btn) {
            color: $color-primary;
        }
    }
}

.info-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    justify-content: space-between;

    &:last-child {
        margin-bottom: 0px;
    }

    h5, .h5 {
        margin-bottom: 0px;
    }
}

